import React, { ReactFragment } from 'react';
import styled from 'styled-components';

import { theme } from '../utils/Theme';

const StyledButton = styled.button<ButtonProps>`
  font-family: Recoleta-SemiBold;
  background-color: ${({ primaryColor }) => (primaryColor ? primaryColor : theme.colors.darkGreen)};
  border: none;
  color: ${({ secondaryColor }) => (secondaryColor ? secondaryColor : theme.colors.white)};
  padding: 9px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  outline: none;
  transition: all 0.2s ease-out;
  border: 1px ${({ primaryColor }) => (primaryColor ? primaryColor : theme.colors.darkGreen)} solid;

  :hover {
    background-color: ${({ secondaryColor }) => (secondaryColor ? secondaryColor : theme.colors.white)};
    color: ${({ primaryColor }) => (primaryColor ? primaryColor : theme.colors.darkGreen)};
  }
`;

interface ButtonProps {
  primaryColor?: string;
  secondaryColor?: string;
  children: ReactFragment;
  type?: 'submit' | 'reset';
  href?: string;
}

export const Button = ({ primaryColor, secondaryColor, children, type }: ButtonProps) => {
  return (
    <StyledButton primaryColor={primaryColor} secondaryColor={secondaryColor} type={type}>
      {children}
    </StyledButton>
  );
};
