import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Button } from '../components/Button';
import { SEO } from '../components/Seo';
import { theme } from '../utils/Theme';

const Container = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  ${theme.mediaQuerySizes.smallLaptop} {
    width: 90%;
    margin: 140px auto 30px auto;
  }
`;

const TopHeadline = styled.h1`
  font-size: 20vw;
  color: ${theme.colors.darkGreen};
  ${theme.mediaQuerySizes.smallLaptop} {
    font-size: 26vw;
  }
  ${theme.mediaQuerySizes.phone} {
    font-size: 34vw;
  }
`;

const SecondaryHeadline = styled.h2`
  font-size: 2vw;
  margin-top: -70px;
  ${theme.mediaQuerySizes.smallLaptop} {
    font-size: 26px;
  }
  ${theme.mediaQuerySizes.phoneLight} {
    margin-top: -40px;
  }
`;

const Description = styled.p`
  font-size: 18px;
`;

const NotFoundPage = () => (
  <Container>
    <SEO title="404: Nie odnaleziono" />
    <TopHeadline>404</TopHeadline>
    <SecondaryHeadline>Upsss... nie odnaleziono strony</SecondaryHeadline>
    <Description>
      Strona którą szukasz, mogła zostać zmieniona
      <br />
      lub jest tymczasowo niedostępna.
    </Description>
    <Link to="/">
      <Button>Przejdź na stronę główną</Button>
    </Link>
  </Container>
);

export default NotFoundPage;
